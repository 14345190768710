import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

import Home from "./pages/Home";
import HomeTwo from "./pages/HomeTwo";
import HomeThree from "./pages/HomeThree";
import About from "./pages/About";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Contact from "./pages/Contact";
import Dashboard from "./pages/Dashboard";
import Header from "./components/ui/Header/Header";
import Sidebar from "./components/ui/Sidebar/Sidebar";
import MySubscription from "./pages/MySubscription";
import ViewReport from "./pages/ViewReport";
import AccountProfile from "./pages/AccountProfile";
import Settings from "./pages/Settings";
import ReportConfiguration from "./pages/ReportConfiguration";
import AddProduct from "./pages/AddProduct";
import DataReport from "./pages/DataReport";
import AddDataReport from "./pages/AddDataReport";
import BiIntegration from "./pages/BiIntegration";
import ManageAdmin from "./pages/ManageAdmin";
import AddAdmin from "./pages/AddAdmin";
import AdminProfile from "./pages/AdminProfile";
import PowerbiReport from "./pages/PowerbiReport";
import ViewPowerBIDash from "./pages/ViewPowerBIDash";
import "./App.css";
import { decryptData } from "./services/authService";
import userService from "./services/userService";
import subscriptionService from "./services/subscriptionService";
import Feedback from "./pages/Feedback";

function DashboardLayout({ children , prop}) {
  // console.log("prop layout", prop)
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={` ${!isSidebarOpen ? "layout-wrapper-off" : ""}`}>
      <Header prop={prop}>
        <Button
          onClick={toggleSidebar}
          variant=""
          className="flex-start toggle-menu-btn me-2"
        >
          <i class="fa-solid fa-arrow-left" style={{ color: "#fff" }}></i>
        </Button>
      </Header>
      <Sidebar prop={prop}></Sidebar>
      <div className="content">{children}</div>
    </div>
  );
}

// function ProtectedRoute({ children, allowedRoles }) {
//   const [role, setRole] = useState(null);

//   useEffect(() => {
//     const storedRole = getRole();
//     setRole(storedRole);
//   }, []);
//   const getRole = async () => {
//     return await decryptData(localStorage.getItem("role"));
//   };

//   console.log("rolee", localStorage.getItem("role"));
//   if (role?.toLowerCase() && allowedRoles?.toLowerCase().includes(role)) {
//     return children;
//   }
//   // If the role is not allowed, redirect to home or login
//   return <Navigate to="/login" />;
// }

function App() {
  const [user, setUser] = useState();
  const [subscription, setSubscription] = useState(false);
  useEffect(() => {
    fetchUser();
  }, []);

  console.log("subscription",subscription)

  const fetchUser = async () => {
    const email = localStorage.getItem("email");
    if (email) {
      await userService
        .getUserByEmail(email)
        .then(async (res) => {
          // console.log("user", res.data.id);
          if (res && res.data && res.data.id) {
            await subscriptionService
              .getSubscriptionByUserId(res.data.id)
              .then((res) => {
                // console.log("res", res);
                if (res?.data?.subscriptionId !== 0) {
                  // console.log(
                  //   "res.data.subscriptionId",
                  //   res.data.subscriptionId
                  // );
                  setSubscription(true);
                } else if (
                  res.data.reports !== null &&
                  res.data.researchData !== null
                ) {
                  // console.log(
                  //   "res.data.subscriptionId",
                  //   res.data.subscriptionId
                  // );
                  setSubscription(true);
                }
              })
              .catch((error) => {});
          }
        })
        .catch((error) => {});
    }
  };

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/HomeTwo" element={<HomeTwo />} />
          <Route path="/HomeThree" element={<HomeThree />} />
          <Route path="/about" element={<About />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/ResetPassword/:emailId" element={<ResetPassword />} />
          <Route path="/Contact" element={<Contact />} />

          <Route
            path="/Dashboards"
            element={
              <DashboardLayout prop={subscription}>
                {subscription ? <MySubscription /> : <Dashboard />}
              </DashboardLayout>
            }
          />

          <Route
            path="/subscription"
            element={
              <DashboardLayout prop={subscription}>
                <Dashboard />
              </DashboardLayout>
            }
          />
          <Route
            path="/MySubscription"
            element={
              <DashboardLayout prop={subscription}>
                <MySubscription />
              </DashboardLayout>
            }
          />
          <Route
            path="/ViewReport/:id"
            element={
              <DashboardLayout>
                <ViewReport />
              </DashboardLayout>
            }
          />
          <Route
            path="/AccountProfile"
            element={
              <DashboardLayout>
                <AccountProfile />
              </DashboardLayout>
            }
          />
          <Route
            path="/Settings"
            element={
              <DashboardLayout>
                <Settings />
              </DashboardLayout>
            }
          />
          <Route
            path="/ReportConfiguration"
            element={
              <DashboardLayout>
                <ReportConfiguration />
              </DashboardLayout>
            }
          />
          <Route
            path="AddProduct"
            element={
              <DashboardLayout>
                <AddProduct />
              </DashboardLayout>
            }
          />

          <Route
            path="AddProduct/:id"
            element={
              <DashboardLayout>
                <AddProduct />
              </DashboardLayout>
            }
          />
          <Route
            path="AddProduct/:id"
            element={
              <DashboardLayout>
                <AddProduct />
              </DashboardLayout>
            }
          />
          <Route
            path="/DataReport"
            element={
              <DashboardLayout>
                <DataReport />
              </DashboardLayout>
            }
          />
          <Route
            path="/AddDataReport"
            element={
              <DashboardLayout>
                <AddDataReport />
              </DashboardLayout>
            }
          />
          <Route
            path="/AddDataReport/:id"
            element={
              <DashboardLayout>
                <AddDataReport />
              </DashboardLayout>
            }
          />
          <Route
            path="/BiIntegration"
            element={
              <DashboardLayout>
                <BiIntegration />
              </DashboardLayout>
            }
          />
          <Route
            path="/ManageAdmin"
            element={
              <DashboardLayout>
                <ManageAdmin />
              </DashboardLayout>
            }
          />
          <Route
            path="/AddAdmin"
            element={
              <DashboardLayout>
                <AddAdmin />
              </DashboardLayout>
            }
          />
          <Route
            path="/AdminProfile/:email"
            element={
              <DashboardLayout>
                <AdminProfile />
              </DashboardLayout>
            }
          />
          <Route
            path="/PowerbiReport"
            element={
              <DashboardLayout>
                <ViewPowerBIDash />
              </DashboardLayout>
            }
          />
          <Route
            path="/Feedback"
            element={
              <DashboardLayout>
                <Feedback />
              </DashboardLayout>
            }
          />
        </Routes>
      </Router>

      {/* <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/ResetPassword/:emailId" element={<ResetPassword />} />
          <Route path="/Contact" element={<Contact />} />

         
          <Route
            path="/Dashboards"
            element={
              <ProtectedRoute allowedRoles={["MEMBER", "ADMIN", "SUPERADMIN"]}>
                <DashboardLayout>
                  <Dashboard />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/MySubscription"
            element={
              <ProtectedRoute allowedRoles={["MEMBER", "ADMIN"]}>
                <DashboardLayout>
                  <MySubscription />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/ViewReport"
            element={
              <ProtectedRoute allowedRoles={["MEMBER", "ADMIN"]}>
                <DashboardLayout>
                  <ViewReport />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/AccountProfile"
            element={
              <ProtectedRoute allowedRoles={["MEMBER", "ADMIN"]}>
                <DashboardLayout>
                  <AccountProfile />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          
          <Route
            path="/Settings"
            element={
              <ProtectedRoute allowedRoles={["ADMIN"]}>
                <DashboardLayout>
                  <Settings />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router> */}
    </div>
  );
}

export default App;
