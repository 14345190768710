import React, { useEffect, useState } from "react";
import "../dashboard.css";
import { Row, Form, Breadcrumb } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { InputGroup, FormControl, Image } from "react-bootstrap";
import editIcon from "../assets/icons/edit.png";
import keyIcon from "../assets/icons/key.png";
import userIcon from "../assets/icons/user.png";
import UserService from "../services/userService";
import { getUserByIdRequestBody } from "../request/requestBodies";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useParams } from "react-router-dom";
const AdminProfile = () => {
  const [user, setUser] = useState("");
  const [key, setKey] = useState("Account");
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const email = localStorage.getItem("email");
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const user = await UserService.getUserByEmail(email)
      .then((res) => {
        console.log("profile response", res);
        if (res && res.data && res.data.data) {
          setFormValues({
            firstName: res.data.data.firstName || "",
            lastName: res.data.data.lastName || "",
            email: res.data.data.email || "",
            phone: res.data.data.phoneNumber || "",
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  // State to manage whether the form is editable or not
  const [isEditable, setIsEditable] = useState(false);
  // State to store form field values
  const [formValues, setFormValues] = useState({
    firstName: "Shantanu",
    lastName: "Sharma",
    email: "Kin@gmail.com",
    memberID: "857457",
  });
  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  // Cancel form editing
  const handleCancelClick = () => {
    setIsEditable(false);
  };
  // Save form changes
  const handleSaveClick = () => {
    console.log("Form submitted:", formValues);
    setIsEditable(false);
  };
  // Function to handle the toggle of the editable state
  const handleEditClick = () => {
    setIsEditable(!isEditable);
  };
  const handleSubmit = () => {};
  return (
    <>
      <div className="content page-wrapper">
        <div className="page-content container">
          <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <Breadcrumb className="mb-0">
              <Breadcrumb.Item
                className="fw-700"
                onClick={() => window.history.back()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="#D7282F"
                    stroke-width="1.5"
                  />
                  <path
                    d="M13.5 16C13.5 16 10.5 13.054 10.5 12C10.5 10.9459 13.5 8 13.5 8"
                    stroke="#D7282F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <strong> Admin Profile</strong>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Form>
                  <Row>
                    <Col lg={8} md={8}>
                      <Row>
                        <Col lg={12}>
                          <div class="d-sm-flex align-items-center gap-x-4">
                            <div className="profile-name mb-2 mb-sm-0">
                              <span
                                style={{ verticalAlign: "middle" }}
                              >{`${formValues.firstName.charAt(
                                0
                              )}${formValues.lastName.charAt(0)}`}</span>
                            </div>
                            <div>
                              <h5 class="text-base font-bold fw-500 ms-0 ms-sm-3">
                                {formValues.firstName} {formValues.lastName}
                              </h5>
                              <div className="d-sm-flex  align-items-center ms-0 ms-sm-3 mt-2">
                                <div>
                                  <h6>
                                    <Image
                                      style={{ width: "14px" }}
                                      src={keyIcon}
                                      className="me-2"
                                    />
                                    Reset password
                                  </h6>
                                </div>
                                <div className="ms-sm-5 mt-2 mt-sm-0">
                                  <div className="d-flex align-items-center toggle-switch">
                                    <Image
                                      style={{ width: "14px" }}
                                      src={userIcon}
                                      className="me-2"
                                    />
                                    <div className="toggle-wrap">
                                      <span
                                        className={
                                          isActive
                                            ? "active-text"
                                            : "inactive-text"
                                        }
                                      >
                                        Active
                                      </span>
                                      <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        checked={isActive}
                                        onChange={handleToggle}
                                        className={
                                          isActive
                                            ? "switch-active"
                                            : "switch-inactive"
                                        }
                                      />
                                      <span
                                        className={
                                          !isActive
                                            ? "active-text"
                                            : "inactive-text"
                                        }
                                      >
                                        Inactive
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={12}>
              <Card className="outer-card">
                <Form>
                  <Row>
                    <Col lg={12} md={12}>
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                      >
                        <Tab eventKey="Account" title="Account">
                          <Row>
                            <Col lg={4} md={6}>
                              <Row>
                                <Col lg={12} md={12}>
                                  <Form.Label
                                    className="fw-500"
                                    style={{ fontSize: "14px" }}
                                  >
                                    First Name
                                  </Form.Label>
                                  <InputGroup className="mb-3 custom-group">
                                    <FormControl
                                      value={formValues.firstName}
                                      onChange={handleInputChange}
                                      disabled={!isEditable}
                                      type="text"
                                      name="firstName"
                                      className="custom-field custom-group-field"
                                      placeholder="First Name"
                                    />
                                    {isEditable && (
                                      <InputGroup.Text
                                        onClick={handleEditClick}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Image
                                          style={{ width: "14px" }}
                                          src={editIcon}
                                        />
                                      </InputGroup.Text>
                                    )}
                                  </InputGroup>
                                </Col>
                                <Col lg={12} md={12}>
                                  <Form.Label
                                    className="fw-500"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Email ID
                                  </Form.Label>
                                  <InputGroup className="mb-3 custom-group">
                                    <Form.Control
                                      type="email"
                                      name="email"
                                      className="custom-field"
                                      placeholder="Email"
                                      value={formValues.email}
                                      onChange={handleInputChange}
                                      disabled={!isEditable}
                                    />
                                    {isEditable && (
                                      <InputGroup.Text
                                        onClick={handleEditClick}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Image
                                          style={{ width: "14px" }}
                                          src={editIcon}
                                        />
                                      </InputGroup.Text>
                                    )}
                                  </InputGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={4} md={6}>
                              <Row>
                                <Col lg={12} md={12}>
                                  <Form.Label
                                    className="fw-500"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Last Name
                                  </Form.Label>
                                  <Form.Group className="mb-2">
                                    <InputGroup className="mb-3 custom-group">
                                      <FormControl
                                        value={formValues.lastName}
                                        onChange={handleInputChange}
                                        disabled={!isEditable}
                                        type="text"
                                        name="firstName"
                                        className="custom-field custom-group-field"
                                        placeholder="First Name"
                                      />
                                      {isEditable && (
                                        <InputGroup.Text
                                          onClick={handleEditClick}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <Image
                                            style={{ width: "14px" }}
                                            src={editIcon}
                                          />
                                        </InputGroup.Text>
                                      )}
                                    </InputGroup>
                                  </Form.Group>
                                </Col>
                                <Col lg={12} md={12}>
                                  <Form.Label
                                    className="fw-500"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Member ID
                                  </Form.Label>
                                  <InputGroup className="mb-3 custom-group">
                                    <Form.Control
                                      type="text"
                                      name="Member ID"
                                      className="custom-field"
                                      placeholder="Member ID"
                                      value={formValues.memberID}
                                      onChange={handleInputChange}
                                      disabled={!isEditable}
                                    />
                                    {isEditable && (
                                      <InputGroup.Text
                                        onClick={handleEditClick}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Image
                                          style={{ width: "14px" }}
                                          src={editIcon}
                                        />
                                      </InputGroup.Text>
                                    )}
                                  </InputGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={12} md={12}>
                              <div className="mt-2 mb-2">
                                <Button
                                  onClick={handleCancelClick}
                                  className="btn-light me-2"
                                >
                                  Cancel
                                </Button>
                                {isEditable ? (
                                  <Button
                                    style={{ fontSize: "14px" }}
                                    onClick={handleSaveClick}
                                    className="btn"
                                  >
                                    Save
                                  </Button>
                                ) : (
                                  <Button
                                    style={{ fontSize: "14px" }}
                                    onClick={handleEditClick}
                                    className="btn"
                                  >
                                    Edit
                                  </Button>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Tab>
                        <Tab
                          eventKey="Transfer Access and Permissions"
                          title="Transfer Access and Permissions"
                        >
                          <Row>
                            <Col lg={4} md={6}>
                              <Row>
                                <Col lg={12} md={12}>
                                  <Form.Label
                                    className="fw-500"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Manage Permission
                                  </Form.Label>
                                  <Form.Select
                                    name="company"
                                    className=" minimal select custom-field custom-select"
                                  >
                                    <option value="1">View only</option>
                                    <option value="2">Can edit</option>
                                  </Form.Select>
                                </Col>
                                <Col lg={12} md={12}>
                                  <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check
                                      required
                                      type="checkbox"
                                      className="text-light-grey mt-3 form_label"
                                      style={{ fontSize: "14px" }}
                                      label={
                                        <>
                                          Want to make Shantanu as Super Admin
                                        </>
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12} md={12}>
                                  <div className="mt-3 mb-2">
                                    <Button
                                      style={{ fontSize: "14px" }}
                                      onClick={handleSaveClick}
                                      className="btn"
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Tab>
                      </Tabs>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default AdminProfile;
