import axios from "axios";
import CryptoJS from "crypto-js";
import authHeader from "./AuthHeader";
import {
    LIST_USER_BY_ROLE
} from "./apiConfig";

class AdminService {
  getAdminsUsers() {
    return axios.post(LIST_USER_BY_ROLE,{role:"ADMIN"} ,{ headers : authHeader()}).then((response) => {
      return response.data;
    });
  }
}

export default new AdminService();
