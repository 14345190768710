import React, { useState } from "react";
import "../dashboard.css";
import { Row, Form, Image } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import editIcon from '../assets/icons/edit.png';

const BiIntegration = () => {
    const [clientID, setClientID] = useState(''); 
    const [clientSecret, setClientSecret] = useState(''); 

    const maskValue = (value) => {
        return value.length > 3
          ? '*'.repeat(value.length - 3) + value.slice(-3)
          : value; 
      };
    

      const handleClientIDChange = (e) => {
        const inputValue = e.target.value;
        setClientID(maskValue(inputValue)); 
      };
    
      const handleClientSecretChange = (e) => {
        const inputValue = e.target.value;
        setClientSecret(maskValue(inputValue)); 
      };
      
    return (
        <>
            <div className="content page-wrapper">
                <div className="page-content">
                    <Row>
                        <Col lg={12}>
                            <Card className="outer-card">
                                <Card.Header className="bg-white border-0 p-0">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <h5 class="mb-0 fw-500">BI Integration</h5>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body className="px-2">
                                    <Row>
                                        <Col lg={12}>
                                            <Form>
                                                <div className="form-inner position-relative p-3 mt-3 pt-4 shadow-none">
                                                    <Row>
                                                        <Col lg={12}>
                                                            <h5
                                                                style={{
                                                                    fontSize: "14px",
                                                                    background: "#fff",
                                                                    padding: "0 0px",
                                                                }}
                                                                className="text-12 mb-4 overlay-title"
                                                            >
                                                                 Details
                                                            </h5>
                                                        </Col>
                                                        <Col lg={4} md={6}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label
                                                                    className="fw-500"
                                                                    style={{ fontSize: "14px" }}
                                                                >
                                                                    Client ID
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="ClientID"
                                                                    value={clientID}
                                                                    onChange={handleClientIDChange}
                                                                    className="custom-field"
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} md={6}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label
                                                                    className="fw-500"
                                                                    style={{ fontSize: "14px" }}
                                                                >
                                                                    Client Secret
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={clientSecret}
                                                                    onChange={handleClientSecretChange}
                                                                    name="ClientSecret"
                                                                    className="custom-field"
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                       
                                                    </Row>
                                                </div>
                                                <Row className="mt-4">
                                                    <Col lg={12} className="add-product-footer-btn text-end">
                                                        <Button className="btn-light me-2"><Image style={{ width: '14px' }} src={editIcon} /> Edit</Button>
                                                        <Button
                                                            style={{ fontSize: "14px" }}
                                                            className="btn"
                                                        >
                                                            Save
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>



        </>
    );
};
export default BiIntegration;