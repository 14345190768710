import { Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { Table, Button, Form, Image, Nav, Modal } from "react-bootstrap";
import "../table.css";
import editIcon from "../assets/icons/edit.png";
import { useEffect, useState } from "react";
import researchDataService from "../services/researchDataService";
import deleteIcon from "../assets/icons/delete.png";

const DataReport = () => {
  const [deleteItem, setDeleteItem] = useState("");
  const [reports, setReports] = useState([]);
  // State to manage modal visibility
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await researchDataService
      .listReports()
      .then((res) => {
        console.log("res", res.data.data);
        setReports(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteCall = async () => {
    await researchDataService
      .deleteResearchData(deleteItem.id)
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="content page-wrapper">
        <div className="page-content">
          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Card.Header className="bg-white border-0 p-0">
                  <div class="d-flex align-items-center">
                    <div>
                      <h5 class="mb-0 fw-500">Data Report</h5>
                    </div>
                    <div class="ms-auto">
                      <Button
                        variant=""
                        style={{ fontSize: "14px" }}
                        href="./AddDataReport"
                      >
                        <i class="fa-solid fa-circle-plus text-white"></i> Add
                        Report
                      </Button>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="p-0">
                  <Row>
                    <Col lg={12}>
                      <Table bordered hover responsive className="mt-3">
                        <thead className="table">
                          <tr>
                            <th className="pl-0 no-wrap-header">
                              <Form.Check type="checkbox" />
                            </th>
                            <th className="no-wrap-header">File Name</th>
                            <th className="no-wrap-header">File Description</th>
                            <th className="no-wrap-header">Workspace ID</th>
                            <th className="no-wrap-header">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reports.length > 0 ? (
                            reports.map((report, index) => (
                              <tr key={index}>
                                <td>
                                  <Form.Check type="checkbox" />
                                </td>
                                <td className="no-wrap-header">
                                  <Nav.Link className="text-decoration-underline">
                                    {report.fileName || "-"}
                                  </Nav.Link>
                                </td>
                                <td>
                                  <p className="overflow-description">
                                    {report.fileDescription}
                                  </p>
                                </td>
                                <td>{report.workspaceID}</td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <Nav.Link variant="link" className="me-3">
                                      <Image
                                        style={{ width: "14px" }}
                                        src={editIcon}
                                      />
                                    </Nav.Link>
                                    <Nav.Link
                                      onClick={(e) => {
                                        setDeleteItem({
                                          id: report.id,
                                          name: report.fileName,
                                        });
                                        handleShow();
                                      }}
                                      variant="link"
                                      className="text-danger"
                                    >
                                      <Image
                                        style={{ width: "14px" }}
                                        src={deleteIcon}
                                      />
                                    </Nav.Link>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colspan="5">
                                <div className="d-flex justify-content-center mb-3 mt-3">
                                   <p>No data found</p>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className="text-center custom-modal"
      >
        <Modal.Header className="justify-content-center border-0">
          <Modal.Title>
            <h4 className="fw-700">Confirm Delete</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete {deleteItem.name}? This action
            cannot be undone
          </p>{" "}
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-0">
          <Button
            className="btn-light"
            style={{ fontSize: "14px" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="btn-danger"
            style={{ fontSize: "14px" }}
            variant="danger"
            onClick={() => {
              deleteCall();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DataReport;
