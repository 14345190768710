// validationFunctions.js

export function validateLoginForm(email, password) {
  const newErrors = {};
  // Email validation
  if (!email) {
    newErrors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(email)) {
    newErrors.email = "Invalid email format";
  }

  // Password validation
  const passwordRequirements =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
  if (!password) {
    newErrors.password = "Password is required";
  } else if (!passwordRequirements.test(password)) {
    newErrors.password =
      "Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character.";
  }

  return newErrors;
}

export function validateRegistrationForm(
  firstName,
  lastName,
  phoneNumber,
  email,
  role,
  company,
  memberNumber,
  password,
  createdBy,
  status
) {
  const newErrors = {};

  if (!firstName) {
    newErrors.firstName = "First name is required";
  }

  if (!lastName) {
    newErrors.lastName = "Last name is required";
  }

  if (!phoneNumber) {
    newErrors.phoneNumber = "Phone number is required";
  }

  if (!email) {
    newErrors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(email)) {
    newErrors.email = "Invalid email format";
  }

  if (!role) {
    newErrors.role = "Role is required";
  }

  if (!company) {
    newErrors.company = "Company is required";
  }

  if (!memberNumber) {
    newErrors.memberNumber = "Member number is required";
  }

  const passwordRequirements =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
  if (!password) {
    newErrors.password = "Password is required";
  } else if (!passwordRequirements.test(password)) {
    newErrors.password =
      "Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character.";
  }

  if (!createdBy) {
    newErrors.createdBy = "Created by is required";
  }

  if (!status) {
    newErrors.status = "Status is required";
  }

  return newErrors;
}

export function validateForgotPasswordForm(emailId) {
  const newErrors = {};

  if (!emailId) {
    newErrors.emailId = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(emailId)) {
    newErrors.emailId = "Invalid email format";
  }

  return newErrors;
}

export function validateResetPasswordForm(newPassword, confirmPassword) {
  const newErrors = {};
  if (!newPassword) {
    newErrors.newPassword = "New password is required";
  } else if (newPassword.length < 6) {
    newErrors.newPassword = "Password must be at least 6 characters long";
  }

  if (!confirmPassword) {
    newErrors.confirmPassword = "Confirm password is required";
  } else if (confirmPassword !== newPassword) {
    newErrors.confirmPassword = "Passwords do not match";
  }

  return newErrors;
}

export function validateGetUserByIdForm(id) {
  const newErrors = {};

  if (!id) {
    newErrors.id = "User ID is required";
  }

  return newErrors;
}

export function validateListUsersForm(pageSize, pageNo) {
  const newErrors = {};

  if (!pageSize) {
    newErrors.pageSize = "Page size is required";
  }

  if (!pageNo) {
    newErrors.pageNo = "Page number is required";
  }

  return newErrors;
}

export function validateCreateProductForm(
  productName,
  productDescription,
  status,
  createdBy
) {
  const newErrors = {};

  if (!productName) {
    newErrors.productName = "Product name is required";
  }

  if (!productDescription) {
    newErrors.productDescription = "Product description is required";
  }

  if (!status) {
    newErrors.status = "Status is required";
  }

  if (!createdBy) {
    newErrors.createdBy = "Created by is required";
  }

  return newErrors;
}

export function validateUpdateProductForm(
  id,
  productName,
  productDescription,
  status,
  updatedBy
) {
  const newErrors = {};

  if (!id) {
    newErrors.id = "Product ID is required";
  }

  if (!productName) {
    newErrors.productName = "Product name is required";
  }

  if (!productDescription) {
    newErrors.productDescription = "Product description is required";
  }

  if (!status) {
    newErrors.status = "Status is required";
  }

  if (!updatedBy) {
    newErrors.updatedBy = "Updated by is required";
  }

  return newErrors;
}

export function validateCreateFeedbackForm(
  userId,
  reportId,
  researchDataId,
  feedbackText,
  createdBy
) {
  const newErrors = {};

  if (!userId) {
    newErrors.userId = "User ID is required";
  }

  if (!reportId) {
    newErrors.reportId = "Report ID is required";
  }

  if (!researchDataId) {
    newErrors.researchDataId = "Research Data ID is required";
  }

  if (!feedbackText) {
    newErrors.feedbackText = "Feedback text is required";
  }

  if (!createdBy) {
    newErrors.createdBy = "Created by is required";
  }

  return newErrors;
}

export function validateUpdateFeedbackForm(id, feedbackText, modifiedBy) {
  const newErrors = {};

  if (!id) {
    newErrors.id = "Feedback ID is required";
  }

  if (!feedbackText) {
    newErrors.feedbackText = "Feedback text is required";
  }

  if (!modifiedBy) {
    newErrors.modifiedBy = "Modified by is required";
  }

  return newErrors;
}

export function validateCreateSubscriptionForm(
  userId,
  reportId,
  productIds,
  researchDataId,
  expirationDate,
  createdBy,
  modifiedBy
) {
  const newErrors = {};

  if (!userId) {
    newErrors.userId = "User ID is required";
  }

  if (!reportId) {
    newErrors.reportId = "Report ID is required";
  }

  if (!productIds || productIds.length === 0) {
    newErrors.productIds = "At least one product ID is required";
  }

  if (!researchDataId) {
    newErrors.researchDataId = "Research Data ID is required";
  }

  if (!expirationDate) {
    newErrors.expirationDate = "Expiration date is required";
  }

  if (!createdBy) {
    newErrors.createdBy = "Created by is required";
  }

  if (!modifiedBy) {
    newErrors.modifiedBy = "Modified by is required";
  }

  return newErrors;
}

export function validateUpdateSubscriptionForm(
  id,
  userId,
  reportId,
  productIds,
  researchDataId,
  expirationDate,
  createdBy,
  modifiedBy
) {
  const newErrors = {};

  if (!id) {
    newErrors.id = "Subscription ID is required";
  }

  if (!userId) {
    newErrors.userId = "User ID is required";
  }

  if (!reportId) {
    newErrors.reportId = "Report ID is required";
  }

  if (!productIds || productIds.length === 0) {
    newErrors.productIds = "At least one product ID is required";
  }

  if (!researchDataId) {
    newErrors.researchDataId = "Research Data ID is required";
  }

  if (!expirationDate) {
    newErrors.expirationDate = "Expiration date is required";
  }

  if (!createdBy) {
    newErrors.createdBy = "Created by is required";
  }

  if (!modifiedBy) {
    newErrors.modifiedBy = "Modified by is required";
  }

  return newErrors;
}

export function validateCreateReportForm(
  reportID,
  reportName,
  reportDescription,
  // sampleImage,
  workspaceID,
  // tenantID
) {
  const newErrors = {};

  if (!reportName) {
    newErrors.reportName = "Report Name is required";
  } else if (/^\d+$/.test(reportName)) {
    newErrors.reportName = "Report Name should not be only numbers";
  } else if (!/^[a-zA-Z0-9-_ ]+$/.test(reportName)) {
    newErrors.reportName =
      "Report Name can only contain letters, numbers, underscores, and hyphens";
  } else if (reportName.length > 100) {
    newErrors.reportName = "Report Name cannot exceed 100 characters";
  }

  // Report ID validation
  if (!reportID) {
    newErrors.reportID = "Report ID is required";
  } else if (!/^[a-zA-Z0-9-_]+$/.test(reportID)) {
    newErrors.reportID =
      "Report ID should be alphanumeric or contain special characters (-, _)";
  } else if (reportID.length > 20) {
    newErrors.reportID = "Report ID cannot exceed 20 characters";
  }

  // Workspace ID validation
  if (!workspaceID) {
    newErrors.workspaceID = "Workspace ID is required";
  } else if (workspaceID.length > 20) {
    newErrors.workspaceID = "Workspace ID cannot exceed 15 characters";
  }

  // Tenant ID validation
  // if (!tenantID) {
  //   newErrors.tenantID = "Tenant ID is required";
  // } else if (tenantID.length > 20) {
  //   newErrors.tenantID = "Tenant ID cannot exceed 15 characters";
  // }

  // Report Description validation
  if (!reportDescription) {
    newErrors.reportDescription = "Report Description is required";
  } else if (reportDescription.length > 5000) {
    newErrors.reportDescription =
      "Report Description cannot exceed 5000 characters";
  }

  // Image Upload Validation
  // if (sampleImage) {
  //   const validImageTypes = ["image/jpeg", "image/png"];
  //   if (!validImageTypes.includes(sampleImage.type)) {
  //     newErrors.sampleImage = "Invalid file format. Only JPEG or PNG allowed";
  //   } else if (sampleImage.size > 5 * 1024 * 1024) {
  //     newErrors.sampleImage = "File size exceeds the limit of 5MB";
  //   }
  // }

  return newErrors;
}

export function validateUpdateReportForm(
  id,
  reportID,
  reportName,
  reportDescription,
  sampleImage,
  workspaceID,
  tenantID,
  status,
  createdBy,
  productIds
) {
  const newErrors = {};

  if (!id) {
    newErrors.id = "Report ID is required";
  }

  if (!reportID) {
    newErrors.reportID = "Report ID is required";
  }

  if (!reportName) {
    newErrors.reportName = "Report name is required";
  }

  if (!reportDescription) {
    newErrors.reportDescription = "Report description is required";
  }

  if (!sampleImage) {
    newErrors.sampleImage = "Sample image is required";
  } else if (!/^https?:\/\/.*\.(jpg|jpeg|png|gif)$/.test(sampleImage)) {
    newErrors.sampleImage = "Invalid image URL";
  }

  if (!workspaceID) {
    newErrors.workspaceID = "Workspace ID is required";
  }

  if (!tenantID) {
    newErrors.tenantID = "Tenant ID is required";
  }

  if (!status) {
    newErrors.status = "Status is required";
  }

  if (!createdBy) {
    newErrors.createdBy = "Created by is required";
  }

  if (!productIds || productIds.length === 0) {
    newErrors.productIds = "At least one product ID is required";
  }

  return newErrors;
}

export function validateCreateResearchDataForm(
  blobLocation,
  blobKey,
  workspaceID,
  tenantID,
  fileName,
  fileDescription
) {
  const newErrors = {};

  if (!blobLocation) {
    newErrors.blobLocation = "Blob location is required";
  } else if (!/^https?:\/\/.*$/.test(blobLocation)) {
    newErrors.blobLocation = "Invalid URL";
  }

  if (!blobKey) {
    newErrors.blobKey = "Blob key is required";
  }

  if (!workspaceID) {
    newErrors.workspaceID = "Workspace ID is required";
  } else if (workspaceID.length > 20) {
    newErrors.workspaceID = "Workspace ID cannot exceed 20 characters";
  }

  // Tenant ID validation
  if (!tenantID) {
    newErrors.tenantID = "Tenant ID is required";
  } else if (tenantID.length > 20) {
    newErrors.tenantID = "Tenant ID cannot exceed 20 characters";
  }

  if (!fileName) {
    newErrors.fileName = " Name is required";
  } else if (/^\d+$/.test(fileName)) {
    newErrors.fileName = " Name should not be only numbers";
  } else if (!/^[a-zA-Z0-9-_ ]+$/.test(fileName)) {
    newErrors.fileName =
      " Name can only contain letters, numbers, underscores, and hyphens";
  } else if (fileName.length > 100) {
    newErrors.fileName = " Name cannot exceed 100 characters";
  }
  if (!fileDescription) {
    newErrors.fileDescription = " Description is required";
  } else if (fileDescription.length > 5000) {
    newErrors.fileDescription = " Description cannot exceed 5000 characters";
  }

  return newErrors;
}

export function validateUpdateResearchDataForm(
  id,
  blobLocation,
  blobKey,
  workspaceID,
  tenantID,
  fileName,
  fileDescription,
  status,
  createdBy
) {
  const newErrors = {};

  if (!id) {
    newErrors.id = "Research data ID is required";
  }

  if (!blobLocation) {
    newErrors.blobLocation = "Blob location is required";
  } else if (!/^https?:\/\/.*$/.test(blobLocation)) {
    newErrors.blobLocation = "Invalid URL";
  }

  if (!blobKey) {
    newErrors.blobKey = "Blob key is required";
  }

  if (!workspaceID) {
    newErrors.workspaceID = "Workspace ID is required";
  }

  if (!tenantID) {
    newErrors.tenantID = "Tenant ID is required";
  }

  if (!fileName) {
    newErrors.fileName = "File name is required";
  }

  if (!fileDescription) {
    newErrors.fileDescription = "File description is required";
  }

  if (!status) {
    newErrors.status = "Status is required";
  }

  if (!createdBy) {
    newErrors.createdBy = "Created by is required";
  }

  return newErrors;
}
