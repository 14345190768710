import React, { useEffect, useState } from "react";
import { Row, ToastContainer } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { Table, Button, Form, Image, Nav, Modal } from "react-bootstrap";
import "../table.css";
import editIcon from "../assets/icons/edit.png";
import deleteIcon from "../assets/icons/delete.png";
import { Navigate, NavLink } from "react-router-dom";
import ReportService from "../services/reportService";
import { toast } from "react-toastify";
const ReportConfiguration = () => {
  const [deleteItem, setDeleteItem] = useState("");
  const [reports, setReports] = useState([
    {
      id: 1234,
      name: "TDM Report",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit",
      workspaceId: 26180,
      tenantId: 57400,
    },
    {
      id: 1234,
      name: "Membership Report",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      workspaceId: 26180,
      tenantId: 57400,
    },
    {
      id: 1234,
      name: "Dubai Startup Report",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      workspaceId: 26180,
      tenantId: 57400,
    },
    {
      id: 1234,
      name: "Customer 360 Report",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      workspaceId: 26180,
      tenantId: 57400,
    },
    {
      id: 1234,
      name: "Business Council Report",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      workspaceId: 26180,
      tenantId: 57400,
    },
    {
      id: 1234,
      name: "Business Group Report",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      workspaceId: 26180,
      tenantId: 57400,
    },
  ]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await ReportService.listReports().then((res) => {
      setReports(res.data);
    });
  };

  const deleteCall = async () => {
    await ReportService.deleteReport(deleteItem.id)
      .then((res) => {
        toast.success("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
        });
        setTimeout(() => {
          window.location.reload(); // This will refresh the page
        }, 3000);
      })
      .catch((error) => {
        toast.error("Error occurred while Deleting!", {
          position: "top-right",
          autoClose: 3000,
        });
        console.log(error);
      });
  };

  // State to manage modal visibility
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <ToastContainer />
      <div className="content page-wrapper">
        <div className="page-content">
          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Card.Header className="bg-white border-0 p-0">
                  <div class="d-flex align-items-center">
                    <div>
                      <h5 class="mb-0 fw-500">Report Configuration</h5>
                    </div>
                    <div class="ms-auto">
                      <Button
                        variant=""
                        style={{ fontSize: "14px" }}
                        href="./AddProduct"
                      >
                        <i class="fa-solid fa-circle-plus text-white"></i> Add
                        Report
                      </Button>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="p-0">
                  <Row>
                    <Col lg={12}>
                      <Table bordered hover responsive className="mt-3">
                        <thead className="table">
                          <tr>
                            <th className="no-wrap-header">
                              <Form.Check type="checkbox" />
                            </th>
                            <th className="no-wrap-header">Report ID</th>
                            <th className="no-wrap-header">Name</th>
                            <th className="no-wrap-header">Description</th>
                            <th className="no-wrap-header">Workspace ID</th>
                            <th className="no-wrap-header">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reports?.length === 0 ? (
                            <div className="d-flex justify-content-center mb-3">
                              <p>No data found</p>
                            </div>
                          ) : (
                            reports?.map((report, index) => (
                              <tr key={index}>
                                <td>
                                  <Form.Check type="checkbox" />
                                </td>
                                <td className="cus-td no-wrap-text">
                                  <Nav.Link
                                    href={`/AddProduct/${report.id}`}
                                    className="text-decoration-underline overflow-descriptions"
                                    title={report.reportID}
                                  >
                                    {report.reportID}
                                  </Nav.Link>
                                </td>
                                <td className="no-wrap-header cus-td no-wrap-text">
                                  <Nav.Link
                                    href={`/AddProduct/${report.id}`}
                                    className="text-decoration-underline overflow-descriptions"
                                    title={report.reportName}
                                  >
                                    {report.reportName}
                                  </Nav.Link>
                                </td>
                                <td className="cus-td no-wrap-text">
                                  <p
                                    title={report.reportDescription}
                                    className="overflow-descriptions"
                                  >
                                    {report.reportDescription}
                                  </p>
                                </td>
                                <td
                                  className="no-wrap-text"
                                  title={report.workspaceID}
                                >
                                  {report.workspaceID}
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <Nav.Link
                                      variant="link"
                                      href={`/AddProduct/${report.id}`}
                                      className="me-3"
                                    >
                                      <Image
                                        style={{ width: "14px" }}
                                        src={editIcon}
                                      />
                                    </Nav.Link>
                                    <Nav.Link
                                      onClick={(e) => {
                                        setDeleteItem({
                                          id: report.id,
                                          name: report.reportName,
                                        });
                                        handleShow();
                                      }}
                                      variant="link"
                                      className="text-danger"
                                    >
                                      <Image
                                        style={{ width: "14px" }}
                                        src={deleteIcon}
                                      />
                                    </Nav.Link>
                                  </div>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {/* Modal */}
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className="text-center custom-modal"
      >
        <Modal.Header className="justify-content-center border-0">
          <Modal.Title>
            <h4 className="fw-700">Confirm Delete</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete {deleteItem.name}? This action
            cannot be undone
          </p>{" "}
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-0">
          <Button
            className="btn-light"
            style={{ fontSize: "14px" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="btn-danger"
            style={{ fontSize: "14px" }}
            variant="danger"
            onClick={() => {
              deleteCall();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReportConfiguration;
