import axios from "axios";
import CryptoJS from "crypto-js";
import authHeader from "./AuthHeader";
import {
  AUTHENTICATION_URL,
  GENERATE_OTP_URL,
  FORGOTPASSWORD_URL,
  REGISTRATION_URL,
} from "./apiConfig";
import roles from "../request/role";

const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey).toString();
};

export const decryptData = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

class AuthService {
  login(username, password) {
    return axios
      .post(AUTHENTICATION_URL, { username, password })
      .then(async (response) => {
        const encryptedRole = await encryptData(response.data.data.role);
        localStorage.setItem("role", encryptedRole);
        localStorage.setItem("email", response.data.data.email);
        if (response.data.data.jwtToken) {
          localStorage.setItem("token", response.data.data.jwtToken);
          if (response.data.data.role === roles.SUPERADMIN) {
            window.location.href = "/ReportConfiguration";
          }
          if (response.data.data.role === roles.ADMIN) {
            window.location.href = "/ReportConfiguration";
          }
          if (response.data.data.role === roles.MEMBER) {
            window.location.href = "/Dashboards";
          }
        }
        return response.data.data;
      });
  }

  forgotPasswordSendOTP(emailId) {
    return axios.post(GENERATE_OTP_URL, { emailId }).then((response) => {
      return response.data;
    });
  }

  forgotPassword(emailId, newpassword) {
    return axios
      .post(FORGOTPASSWORD_URL, { emailId, newpassword })
      .then((response) => {
        return response.data;
      });
  }

  register(requestBody) {
    return axios
      .post(REGISTRATION_URL, requestBody, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
}

export default new AuthService();
