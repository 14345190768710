import axios from "axios";
import {
  CREATE_SUBSCRIPTION_URL,
  GET_SUBSCRIPTION_BY_ID_URL,
  UPDATE_SUBSCRIPTION_URL,
  DELETE_SUBSCRIPTION_URL,
  LIST_SUBSCRIPTIONS_URL,
  GET_SUBSCRIPTION_BY_USER_ID,
} from "./apiConfig";
import authHeader from "./AuthHeader";

class SubscriptionService {
  createSubscription(subscriptionData) {
    console.log("subscriptionData", subscriptionData);
    return axios
      .post(
        CREATE_SUBSCRIPTION_URL,
        {
          userId: subscriptionData.userId,
          reportProductMapping: subscriptionData.reportProductMapping,
          expirationDate: subscriptionData.expirationDate,
          createdBy: subscriptionData.createdBy,
          modifiedBy: subscriptionData.modifiedBy,
          researchDataIds: subscriptionData.researchDataIds,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  getSubscriptionById(id) {
    return axios.post(GET_SUBSCRIPTION_BY_ID_URL, { id }).then((response) => {
      return response.data;
    });
  }

  updateSubscription(subscriptionData) {
    return axios
      .put(UPDATE_SUBSCRIPTION_URL, subscriptionData)
      .then((response) => {
        return response.data;
      });
  }

  deleteSubscription(id) {
    return axios
      .delete(DELETE_SUBSCRIPTION_URL.replace("{id}", id))
      .then((response) => {
        return response.data;
      });
  }

  listSubscriptions() {
    return axios
      .get(LIST_SUBSCRIPTIONS_URL, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  getSubscriptionByUserId(id) {
    return axios
      .post(GET_SUBSCRIPTION_BY_USER_ID, { userId: id }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }
}

export default new SubscriptionService();
