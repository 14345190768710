import axios from "axios";
import {
  CREATE_RESEARCH_DATA_URL,
  GET_RESEARCH_DATA_BY_ID_URL,
  UPDATE_RESEARCH_DATA_URL,
  DELETE_RESEARCH_DATA_URL,
  LIST_RESEARCH_DATA_URL,
} from "./apiConfig";
import authHeader from "./AuthHeader";

class ResearchDataService {
  createResearchData(reportData) {
    return axios
      .post(CREATE_RESEARCH_DATA_URL, reportData, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  getResearchDataById(id) {
    return axios.post(GET_RESEARCH_DATA_BY_ID_URL, { id }, {headers: authHeader()}).then((response) => {
      return response.data;
    });
  }

  updateResearchData(reportData) {
    return axios.put(UPDATE_RESEARCH_DATA_URL, reportData).then((response) => {
      return response.data;
    });
  }

  deleteResearchData(id) {
    console.log("id",id)
    return axios
      .delete(DELETE_RESEARCH_DATA_URL + id, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  listReports() {
    return axios
      .get(LIST_RESEARCH_DATA_URL, {
        headers: authHeader(),
      })
      .then((response) => {
        console.log("respons from list", response)
        return response;
      });
  }
}

export default new ResearchDataService();
