import React, { Fragment } from 'react'
import { Container, Row, Col, Button, Image, NavDropdown } from 'react-bootstrap';
import "../main.css";
import "../home.css";
import logo from "../assets/w-logo.png";
import logo2 from "../assets/d-logo.svg";
import banner from "../assets/banner.png";
import help from "../assets/icons/headphones.png";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';



const Home = () => {

  
  return (
    <div className="page-wrap wrap-page d-flex flex-column min-vh-100">

      <Navbar sticky="top" expand="lg" className="dc-header bg-white headerbg-body-tertiary justify-content-between">
        <Container className='navbar-container'>
          <Navbar.Brand href="#home"><Image src={logo2} alt="Dubai Chambers Logo" className="logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav className="my-2  my-lg-0 justify-content-end">
              <Nav.Link href="/login">Login</Nav.Link>
              <Button variant="primary"  href="/register" style={{ fontSize: '14px', width: 'max-content', lineHeight:'inherit' }}>Sign Up</Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Main Content */}
      <div className='mainPage flex-grow-1'>
      <div className="main-content banner-section">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <h1 className='mb-3'>Welcome to the Business Insight Tracker</h1>
              <p className="description">
              Unlock essential data to drive your business growth. Our platform offers three specialized dashboards—Trade Data, Country Fact Sheets, and Business Analytics—designed to provide you with actionable insights.
              </p>
              <ul className='mt-3' style={{marginLeft:'15px'}}>
                <li className='mb-2'><strong>Trade Data Dashboard:</strong> Explore UAE’s trade trends in 20 strategic food products, uncover key global partners, and track market growth for informed decision-making.</li>
                <li className='mb-2'><strong>Country Fact Sheet:</strong> Access comprehensive economic indicators, market competitiveness, and business opportunities to help you navigate global markets with confidence.</li>
                <li className='mb-2'><strong>Dubai Chambers Members' Exports:</strong> Access the latest data on export transactions, values, and exporter counts, with three-month growth insights to help you stay informed and ahead in global trade.</li>
              </ul>
              <p className='mt-2'>Empower your decisions with real-time insights and grow your business with confidence.</p>
            </Col>
            <Col md={6}>
              <div className="images">
                <Image src={banner} alt="Banner" className="w-100 mt-5 mt-lg-0" />

              </div>
            </Col>
          </Row>
        </Container>
      </div>
      </div>

      {/* Footer Section */}
      <footer className="d-flex justify-content-between align-items-center d-flex justify-content-between align-items-center">
        <Container>
          <Row>
            <Col lg={3}>
              <div className='footer-logo'>
                <Image src={logo} alt="" />
              </div>
            </Col>
            <Col lg={3}>
              <div>
                <h5 className='mt-4 mt-lg-0 mb-4'>Contact Us</h5>
                <div className='d-flex'>
                <i class="fa-solid fa-phone text-white me-2" style={{fontSize:'12px',lineHeight:'125%'}}></i>
                <div>
                <p>International: (+971) 4 228 0000 </p>
                <p>Fax: (+971) 4 202 8888 </p>
                <p>Email: customercare@dubaichamber.com</p>
                </div>
              </div>
              </div>
            </Col>
            <Col lg={4}>
              <div>
                <h5 className='mt-4 mt-lg-0 mb-4'>Address:</h5>
                <div className='d-flex'>
                <i class="fa-solid fa-location-dot text-white me-2" style={{fontSize:'12px',lineHeight:'125%'}}></i>
                <p>Dubai Chambers, Baniyas Road, Deira, Dubai, U.A.E <br />
                  P.O Box: 1457 <br />
                  Makani: 3035894820</p>
                </div>
              </div>
            </Col>
            <Col lg={2}>
              <div className="mt-4 mt-lg-0">
                <Button style={{ fontSize: '14px' }} className="btn btn-red" href="/Contact">
                  <Image style={{ width: '16px' }} className='me-2' src={help} />
                  Help
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  )
}

export default Home
