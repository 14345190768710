import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { Table, Button, Form, Image, Nav, Modal } from "react-bootstrap";
import "../table.css";
import editIcon from "../assets/icons/edit.png";
import deleteIcon from "../assets/icons/delete.png";
import { NavLink } from "react-router-dom";
import adminService from "../services/adminService";

const ManageAdmin = () => {
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await adminService.getAdminsUsers().then((res) => {
      console.log("res", res.data);
      setAdmins(res.data);
    });
  };
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <div className="content page-wrapper">
        <div className="page-content">
          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Card.Header className="bg-white border-0 p-0">
                  <div class="d-flex align-items-center">
                    <div>
                      <h5 class="mb-0 fw-500">Manage Admin</h5>
                    </div>
                    <div class="ms-auto">
                      <Button
                        variant=""
                        style={{ fontSize: "14px" }}
                        href="./AddAdmin"
                      >
                        <i class="fa-solid fa-circle-plus text-white"></i> Add
                        Admin
                      </Button>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="p-0">
                  <Row>
                    <Col lg={12}>
                      <Table bordered hover responsive className="mt-3">
                        <thead className="table">
                          <tr>
                            <th className="no-wrap-header">
                              <Form.Check type="checkbox" />
                            </th>
                            <th className="no-wrap-header">Member ID</th>
                            <th className="no-wrap-header">Name</th>
                            <th className="no-wrap-header">Status</th>
                            <th className="no-wrap-header">Permission</th>
                            <th className="no-wrap-header">Created Date</th>
                            <th className="no-wrap-header">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {admins &&
                            admins.length !== 0 &&
                            admins.map((report, index) => (
                              <tr key={index}>
                                <td>
                                  <Form.Check type="checkbox" />
                                </td>
                                <td>
                                  <Nav.Link className="text-decoration-underline">
                                    {report.id}
                                  </Nav.Link>
                                </td>
                                <td>
                                  <Nav.Link
                                    href={`/AdminProfile/${report.email}`}
                                    className="text-decoration-underline"
                                  >
                                    {report.name}
                                  </Nav.Link>
                                </td>
                                <td className="no-wrap-header">
                                  <Nav.Link>{report.status}</Nav.Link>
                                </td>
                                <td className="no-wrap-header">
                                  <Nav.Link>{report.permission}</Nav.Link>
                                </td>
                                <td>
                                  <p className="overflow-description">
                                    {report.createdDate}
                                  </p>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <Nav.Link variant="link" className="me-3">
                                      <Image
                                        style={{ width: "14px" }}
                                        src={editIcon}
                                      />
                                    </Nav.Link>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          {admins.length === 0 && (
                            <div className="d-flex justify-content-center mb-3">
                              <p>No data found</p>
                            </div>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {/* Modal */}
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className="text-center custom-modal"
      >
        <Modal.Header className="justify-content-center border-0">
          <Modal.Title>
            <h4 className="fw-700">Confirm Delete</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete TDM report from system? This action
            cannot be undone
          </p>{" "}
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-0">
          <Button
            className="btn-light"
            style={{ fontSize: "14px" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="btn-danger"
            style={{ fontSize: "14px" }}
            variant="danger"
            onClick={() => {
              // Your delete logic here
              handleClose();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManageAdmin;
