import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Modal,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../assets/d-logo.svg";
import backIcon from "../assets/icons/backIcon.svg";
import "../login.css";
import "../main.css";

const Contact = () => {
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Logic to send mail goes here
    setShowModal(true); // Show modal when mail is sent
  };

  const handleClose = () => {
   window.location.reload()
  };

  return (
    <div id="main-wrapper" className="login authentication-page">
      <Container fluid className="px-0">
        <Row className="g-0 min-vh-100">
          <Col md={6} lg={6} className="d-flex flex-column align-items-center">
            <Container fluid className="pt-4">
              <Row className="g-0">
                <Col xs={12}>
                  <div className="logo px-5 navlogo">
                    <a
                      className="fw-600 text-6 text-dark link-underline link-underline-opacity-0"
                      href="index.html"
                      title="Dubai Chambers"
                    >
                      <Image src={logo} alt="Logo" />
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>

            <Container fluid className="my-auto py-5">
              <Row className="g-0">
                {/* <Col xs={11} md={10} lg={9} className="mx-auto"> */}
                <Col xs={12} className="mx-auto px-5">
                  <h5
                    className="d-flex align-items-center mb-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => window.history.back()}
                  >
                    <Image
                      className="me-1"
                      src={backIcon}
                      style={{ width: "18" }}
                      alt="back"
                    />
                    Back
                  </h5>
                  <div className="form-inner">
                    <h1 className="text-12 mb-4 text-center">Contact Us</h1>
                    <Form id="loginForm" onSubmit={handleSubmit}>
                      <Form.Group controlId="emailAddress">
                        <Form.Label className="fw-500">
                          Your Email Address
                        </Form.Label>
                        <div className="mb-3 icon-group icon-group-end">
                          <Form.Control
                            type="email"
                            className="bg-light border-light"
                            placeholder="a@example.com"
                            required
                          />
                        </div>
                      </Form.Group>

                      <Form.Group controlId="formTextarea">
                        <Form.Label className="fw-500">Comment</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder=""
                          required
                        />
                      </Form.Group>

                      <div className="d-grid my-4">
                        <Button type="submit" className="btn-dark btn-lg">
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md={6} lg={6} className="bg-light">
            <div className="hero-wrap d-flex align-items-center h-100">
              <div className="login-image-container hero-bg hero-bg-scroll">
                <div className="fixed-btn"></div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Modal for mail sent confirmation */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Mail Sent</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your message has been successfully sent.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Contact;
