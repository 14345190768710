import React, { useEffect, useState } from "react";
import PowerbiReport from "./PowerbiReport";
import reportService from "../services/reportService";
const ViewPowerBIDash = () => {
    const [embedData, setEmbedData] = useState(null);
  
    useEffect(() => {
      fetchData()
    }, []);

    const fetchData = async()=>{
        await reportService.getReport("1").then((res)=>{
            setEmbedData(res)
        })
    }
  
    return (
    <>        {embedData ? <PowerbiReport embedData={embedData} /> : 'Loading report...'}
</>

    );
  };

  export default ViewPowerBIDash;
  