import React, { useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../dashboard.css";
import { Row, Form, Breadcrumb, Image, NavLink, Nav } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import backIcon from "../assets/icons/backIcon.png";

const PowerbiReport = ({ embedData }) => {
  const [embedConfig, setEmbedConfig] = useState(null);
  const [reportInstance, setReportInstance] = useState(null); // Store the embedded report instance

  useEffect(() => {
    if (
      embedData &&
      embedData.embedReports &&
      embedData.embedReports.length > 0
    ) {
      const report = embedData.embedReports[0]; // Accessing the first report in the response

      // Set up the configuration with the data from embedData
      setEmbedConfig({
        type: "report",
        id: report.reportId, // reportId from embedData
        embedUrl: report.embedUrl, // embedUrl from embedData
        accessToken: embedData.embedToken, // embedToken from embedData
        tokenType: models.TokenType.Embed,
        settings: {
          filterPaneEnabled: false, // Set to true to enable the filter pane
          navContentPaneEnabled: false, // Set to true to enable the navigation pane
        },
      });
    }
  }, [embedData]);

  // Apply report-level filter
  const applyReportFilter = async (report) => {
    const filter = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "Products Icon Filter",
        column: "NewProduct",
      },
      operator: "Contains",
      values: ["Apple", "Banana", "Dates"],
    };

    try {
      await report.setFilters([filter]);
      console.log("Report-level filter applied");
    } catch (error) {
      console.error("Error applying report filter:", error);
    }
  };

  // Apply visual-level filter
  const applyVisualFilter = async (report) => {
    const filter = {
      $schema: "http://powerbi.com/product/schema#advanced",
      target: {
        table: "Products Icon",
        column: "_Key",
      },
      filterType: models.FilterType.Advanced,
      logicalOperator: "Or",
      conditions: [
        { operator: "Contains", value: "Apple" },
        { operator: "Contains", value: "Banana" },
        { operator: "Contains", value: "Dates" },
      ],
    };

    try {
      const pages = await report.getPages();
      const activePage = pages.find((page) => page.isActive);

      if (activePage) {
        const visuals = await activePage.getVisuals();
        const targetVisual = visuals.find(
          (visual) => visual.name === "205a1c166d10abd40e78"
        );

        if (targetVisual) {
          await targetVisual.updateFilters(models.FiltersOperations.Add, [
            filter,
          ]);
          console.log("Visual-level filter applied");
        } else {
          console.log("Target visual not found.");
        }
      }
    } catch (error) {
      console.error("Error applying visual filter:", error);
    }
  };

  if (!embedConfig) {
    return <div>Loading Power BI report...</div>;
  }

  return (
    <div className="content page-wrapper">
      <div className="page-content">
        <div class="page-breadcrumb  d-sm-flex align-items-center mb-3">
          <Breadcrumb className="mb-0">
            <Breadcrumb.Item>
              <Image
                className="me-1"
                src={backIcon}
                style={{
                  width: "16px",
                  cursor: "pointer",
                  verticalAlign: "text-bottom",
                }}
                onClick={() => window.history.back()}
                alt="back"
              />{" "}
              <a href="/Dashboards" className="fw-400">Home</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="fw-700 no-link"
              style={{ cursor: "unset" }}
            >
              <strong className="fw-500">Power BI Report</strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Row>
          <Col lg={12}>
            <div style={{ height: "100vh", width: "100%" }}>
              <PowerBIEmbed
                embedConfig={embedConfig}
                cssClassName={"report-style-class"}
                eventHandlers={
                  new Map([
                    [
                      "loaded",
                      async function (event, report) {
                        console.log("Report loaded");
                        setReportInstance(report);
                        // Apply report-level filters after loading
                        await applyReportFilter(report);
                        // Apply visual-level filters
                        await applyVisualFilter(report);
                      },
                    ],
                    [
                      "rendered",
                      function () {
                        console.log("Report rendered");
                      },
                    ],
                    [
                      "error",
                      function (event) {
                        console.log("Report error", event.detail);
                      },
                    ],
                  ])
                }
                getEmbeddedComponent={(embeddedReport) => {
                  console.log("Embedded Report:", embeddedReport);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PowerbiReport;
