import React from "react";
import { Card, Button, Row, Col, Image, Nav } from "react-bootstrap";
import "../../../src/dashboard.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const CustomCardForSubscribedReport = ({
  id,
  title,
  imageSrc,
  previewSrc,
  description,
  previewAlt = "Click to Preview",
  buttonLabel ,
  downloadUrl, // Assuming the download URL comes from props
}) => {
  const navigate = useNavigate();

  const handleDownload = async (e) => {
    e.preventDefault();
    try {
      // Make an API call to download the file as a blob
      const response = await axios.get(downloadUrl, {
        responseType: "blob", // Set response type to blob
      });

      // Create a blob URL and trigger the download
      const blob = new Blob([response.data], { type: response.headers["content-type"] });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = title; // The downloaded file name can be set here
      document.body.appendChild(link);
      link.click();

      // Clean up the DOM
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  return (
    <Col lg={4} sm={6} className="d-flex mb-4">
      <Card className="custom-card inner-card w-100">
        <Card.Body className="p-0">
          <Row>
            <Col lg={12}>
              <div className="card-header-section">
                <Card.Title className="card-title custom-card-title">
                  {title}
                </Card.Title>
              </div>

              <div className="card-image">
                <Image src={imageSrc} alt="Report" className="image-hover" />
                <div className="preview-link">
                  <Image
                    src={previewSrc}
                    alt={previewAlt}
                    className="preview-image"
                  />
                  <Nav.Link
                    onClick={(e) => {
                      e.preventDefault();
                      navigate({ pathname: "/ViewReport/" + id });
                    }}
                    className="text-danger p-0 fw-400"
                  >
                    {previewAlt}
                  </Nav.Link>
                </div>
              </div>

              <Card.Text className="card-text">{description}</Card.Text>

              <div className="card-footer-section mt-0">
                <Button
                  className="btn"
                  onClick={(e)=>{e.preventDefault(); navigate("/PowerbiReport")}}
                  style={{ fontSize: "12px" }}
                >
                  {buttonLabel}
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default CustomCardForSubscribedReport;
