import React from "react";
import { Card, Row, Col, Button, Image, Form, Nav } from "react-bootstrap";
import "../../../src/dashboard.css";
import preview from "../../assets/icons/view.png";
const CustomCardWithProduct = ({
  error,
  title,
  description,
  imageSrc,
  altText,
  onSelectProduct,
  onPreview,
  checkbox,
  showSelectButton = true,
  showPreviewButton = true,
}) => {
  // console.log("checkbox.checked",title,checkbox.checked)
  return (
    <Col lg={4} sm={6} className="d-flex mb-4">
      {checkbox && (
        <Form.Check
        checked={checkbox.checked}
          type="checkbox"
          className="checkbox-custom mt-0"
          onChange={checkbox.onChange}
        />
      )}
      <Card className="custom-card inner-card w-100">
        <Card.Body className="p-0">
          <Row>
            <Col lg={12}>
              <div className="card-header-section">
                <div>
                  <Card.Title className="card-title custom-card-title">
                    {title}
                  </Card.Title>
                </div>
              </div>
              <div className="card-image">
                <Image src={imageSrc} alt="Report" className="image-hover" />
                <div className="preview-link">
                  <Image
                    src={preview}
                    alt="Click to Preview"
                    className="preview-image"
                  />
                  <Nav.Link
                    onClick={(e) => {
                      e.preventDefault();
                      onPreview();
                    }}
                    className="text-danger p-0 fw-400"
                  >
                    Click to Preview
                  </Nav.Link>
                </div>
              </div>

              <Card.Text className="card-text">{description}</Card.Text>
              <div className="card-footer-section mt-0">
                <Button
                  className="btn btn-light"
                  onClick={onSelectProduct}
                  // onClick={() => setShowModal(true)}
                  style={{ fontSize: "12px" }}
                >
                  Select Product
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Body>
        {error && (
          <Card.Footer style={{ font: "small-caption", color: "red" }}>
            {error || null}
          </Card.Footer>
        )}
      </Card>
    </Col>
  );
};

export default CustomCardWithProduct;
