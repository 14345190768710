import React, { useEffect, useState } from "react";
import "../dashboard.css";
import { Row, Form, Image, Breadcrumb } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { Alert } from "react-bootstrap";
import deleteIcon from "../assets/icons/delete.png";
import uploadIcon from "../assets/icons/upload.png";
import { Table, Nav } from "react-bootstrap";
import editIcon from "../assets/icons/edit.png";
import { validateCreateReportForm } from "../request/validationFunctions";
import ReportService from "../services/reportService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import productService from "../services/productService";
import backIcon from "../assets/backIcon.png";

const AddProduct = () => {
  const navigate = useNavigate();
  // State to manage form data
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [products, setProducts] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const maxSize = 10 * 1024 * 1024; // 10 MB
  const [productsList, setProductsList] = useState([]);
  const [formData, setFormData] = useState({
    reportID: "",
    reportName: "",
    reportDescription: "",
    sampleImage: null,
    workspaceID: "",
    tenantID: "",
    status: "Active",
    createdBy: localStorage.getItem("email"),
    products: [""],
    productTableName: "sales_product",
    productTableColumnName: "product_id",
    productTableFilterOperator: "=",
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
    updatedBy: localStorage.getItem("email"),
  });
  const [initialFormData, setInitialFormData] = useState(formData);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const id = useParams("id");

  useEffect(() => {
    setInitialFormData(formData);
    const controller = new AbortController();
    if (id) {
      fetchData(controller.signal);
    } else {
      setInitialFormData(formData);
    }

    return () => controller.abort(); // Cleanup on unmount
  }, []);

  const fetchData = async (signal) => {
    if (id) {
      await ReportService.getReportById(id)
        .then(async (res) => {
          if (res.data) {
            setFormData({
              id: res.data.id,
              reportID: res.data.reportID || "",
              reportName: res.data.reportName || "",
              reportDescription: res.data.reportDescription || "",
              sampleImage: res.data.sampleImage || null,
              workspaceID: res.data.workspaceID || "",
              tenantID: res.data.tenantID || "",
              status: res.data.status || "Active",
              createdBy: res.data.createdBy || localStorage.getItem("email"),
              products:
                res.data.productIds.length > 0 ? res.data.productIds : [""],
              createdAt: res.data.createdAt || new Date().getTime(),
              updatedAt: res.data.updatedAt || new Date().getTime(),
              updatedBy: res.data.updatedBy || localStorage.getItem("email"),
            });
            if (res.data.productIds.length > 0) {
              for (const product of res.data.productIds) {
                try {
                  const productRes = await productService.getProductById(
                    product
                  );
                  if (productRes.statusCode === 200) {
                    setProductsList((prevData) => {
                      // Check if the product already exists by comparing ids
                      const productExists = prevData.some(
                        (existingProduct) =>
                          existingProduct.id === productRes.data.id
                      );
                      // If product doesn't exist, add it to the list
                      if (!productExists) {
                        return [...prevData, productRes.data];
                      }
                      // Return the previous data if product already exists
                      return prevData;
                    });
                  }
                } catch (error) {
                  console.error("Error fetching product by ID:", error);
                }
              }
            }
          }
        })
        .catch((error) => {});
    }
    //setIsSaveDisabled(true)
  };

  useEffect(() => {
   // setIsExpanded(true);
    setProducts(productsList);
  }, [productsList]);

  const validateProduct = () => {
    let formIsValid = true;
    let newErrors = { productName: "", productDescription: "" };

    if (productName.trim() === "") {
      newErrors.productName = "Product name is required.";
      formIsValid = false;
    }

    if (productDescription.trim() === "") {
      newErrors.productDescription = "Product description is required.";
      formIsValid = false;
    }

    setErrors(newErrors);
    return formIsValid;
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0]; // Get the first file dropped
    handleFile(file);
  };
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    handleFile(file);
  };
  const handleFile = (file) => {
    if (file && file.size <= maxSize && file.type.startsWith("image/")) {
      setImage(file);
      setError(null);
    } else {
      setError("File size exceeds 10 MB or invalid file type.");
    }
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const handleAddProductClick = () => {
    setIsExpanded(!isExpanded); // Toggle between expanded and collapsed states
  };
  const handleSaveClick = () => {
    setIsExpanded(false); // Optionally, you can collapse it on Save
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaveDisabled(true);
    console.log("formData", formData);
    const validationErrors = validateCreateReportForm(
      formData.reportID,
      formData.reportName,
      formData.reportDescription,
      // formData.sampleImage,
      formData.workspaceID
      // formData.tenantID
    );

    if (Object.keys(validationErrors).length > 0) {
      console.log("validationErrors", validationErrors);
      setErrors(validationErrors);
    } else {
      const requestBody = {
        ...formData,
        sampleImage: formData.sampleImage ? formData.sampleImage.name : "",
      };
      console.log("form submitted", requestBody);
      if (id) {
        // Map productIds from products array
        const productIds = formData.products.map((product) => product.id);

        // Create the editRequestBody with the mapped productIds and additional fields
        let editRequestBody = {
          ...formData,
          productIds, // map product ids
        };
        // Remove the unnecessary 'products' array from the final request body
        delete editRequestBody.products;

        await ReportService.updateReport(editRequestBody)
          .then((res) => {
            console.log("res", res);
            toast.success("Success!", {
              position: "top-right",
              autoClose: 1000,
            });
            navigate("/ReportConfiguration");
          })
          .catch((error) => {
            toast.error("Error occurred while saving!", {
              position: "top-right",
              autoClose: 3000,
            });
          });
      } else {
        await ReportService.createReport(requestBody)
          .then((res) => {
            console.log("res", res);
            toast.success("Success!", {
              position: "top-right", // Use string directly if POSITION is undefined
              autoClose: 3000, // duration in milliseconds
            });
            navigate();
          })
          .catch((error) => {
            toast.error("Error occurred while saving!", {
              position: "top-right", // Use string directly
              autoClose: 3000,
            });
          });
      }
    }

    //  await ReportService.createReport()
  };
  const handleInputChange = (e) => {
    setIsSaveDisabled(false);
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleSubmitProduct = () => {
    setIsSaveDisabled(false);
    if (!validateProduct()) {
      return; // Stop submission if validation fails
    } else {
      const newProduct = {
        productName,
        productDescription,
        status: "Active", // Assuming default status is active
        createdBy: localStorage.getItem("email"), // You can dynamically set this
        createdAt: new Date().getTime(),
        updatedAt: new Date().getTime(),
        updatedBy: localStorage.getItem("email"), // You can dynamically set this
      };
      setProducts([...products, newProduct]); // Add new product to the array
      setProductName("");
      setProductDescription("");
    }
  };
  const handleEditProduct = (index) => {
    setIsSaveDisabled(false);
    setIsExpanded(true); // Show the form
    setEditingIndex(index); // Set the index of the product being edited
    setProductName(products[index].productName);
    setProductDescription(products[index].productDescription);
  };
  const handleDeleteProduct = (index) => {
    setIsSaveDisabled(false);
    const updatedProducts = products.filter((_, i) => i !== index);
    setProducts(updatedProducts);
  };
  useEffect(() => {
    setFormData((prevData) => ({ ...prevData, products: products }));
  }, [products]);

  return (
    <>
      <ToastContainer />
      <div className="content page-wrapper">
        <div className="page-content">
          <div class="page-breadcrumb  d-sm-flex align-items-center mb-3">
            <Breadcrumb className="mb-0">
              <Breadcrumb.Item>
                <Image
                  className="me-1"
                  src={backIcon}
                  style={{
                    width: "16px",
                    cursor: "pointer",
                    verticalAlign: "text-bottom",
                  }}
                  onClick={() => window.history.back()}
                  alt="back"
                />{" "}
                <a href="/ReportConfiguration" className="fw-400">Report Configuration</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="fw-700 no-link"
                style={{ cursor: "unset" }}
              >
                <strong className="fw-500">Add Report</strong>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Card.Header className="bg-white border-0 p-0">
                  <div class="d-flex align-items-center">
                    <div>
                      <h5 class="mb-0 fw-500">Report Configuration</h5>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="px-2">
                  <Row>
                    <Col lg={12}>
                      <Form onSubmit={handleSubmit}>
                        <div className="form-inner position-relative p-3 mt-3 pt-4 shadow-none">
                          <Row>
                            <Col lg={12}>
                              <h5
                                style={{
                                  fontSize: "14px",
                                  background: "#fff",
                                  padding: "0 0px",
                                }}
                                className="text-12 mb-4 overlay-title"
                              >
                                Report Details
                              </h5>
                            </Col>
                            <Col lg={4} md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Report Name
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="reportName"
                                  className="custom-field"
                                  value={formData.reportName}
                                  onChange={handleInputChange}
                                  isInvalid={!!errors.reportName}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.reportName}
                                </Form.Control.Feedback>
                                {/* {errors.reportName && (
                                  <Alert variant="danger">
                                    {errors.reportName}
                                  </Alert>
                                )} */}
                              </Form.Group>
                            </Col>
                            <Col lg={4} md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Report ID
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="reportID"
                                  className="custom-field"
                                  value={formData.reportID}
                                  onChange={handleInputChange}
                                  isInvalid={!!errors.reportID}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.reportID}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={4} md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Workspace ID
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="workspaceID"
                                  className="custom-field"
                                  value={formData.workspaceID}
                                  onChange={handleInputChange}
                                  isInvalid={!!errors.workspaceID}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.workspaceID}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={4} md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Tenant ID
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="tenantID"
                                  className="custom-field"
                                  value={formData.tenantID}
                                  onChange={handleInputChange}
                                  isInvalid={!!errors.tenantID}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.tenantID}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={4} md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Report Description
                                </Form.Label>
                                <Form.Group controlId="formTextarea">
                                  <Form.Control
                                    as="textarea"
                                    name="reportDescription"
                                    className="custom-field overflow-hidden"
                                    rows={5}
                                    placeholder=""
                                    value={formData.reportDescription}
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.reportDescription}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.reportDescription}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Form.Group>
                            </Col>
                            <Col lg={4} md={6}>
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                Upload Sample Image
                              </Form.Label>
                              <Form.Group>
                                <div className="position-relative upload-image-wrap">
                                  <div
                                    className="border rounded p-2 px-5 text-center"
                                    style={{
                                      backgroundColor: "#f4f4f4",
                                      cursor: "pointer",
                                    }}
                                    onDrop={handleDrop}
                                    onDragOver={handleDragOver}
                                  >
                                    {image ? (
                                      <div className="preview-image-box">
                                        <img
                                          src={URL.createObjectURL(image)}
                                          alt="preview"
                                          style={{ height: "60px" }}
                                          className=""
                                        />
                                        <p
                                          className="overflow-description"
                                          style={{
                                            marginTop: "10px",
                                            fontSize: "12px",
                                          }}
                                          title={image.name}
                                        >
                                          {image.name} (
                                          {(image.size / 1024 / 1024).toFixed(
                                            2
                                          )}{" "}
                                          MB)
                                        </p>
                                      </div>
                                    ) : (
                                      <div className="upload-inner">
                                        <p
                                          className="mb-0"
                                          style={{ fontSize: "12px" }}
                                        >
                                          <Image
                                            style={{ width: "14px" }}
                                            src={uploadIcon}
                                          />
                                          <div>
                                            Drop your image here or <br />
                                          </div>
                                          <label
                                            htmlFor="file-upload"
                                            className="btn  mt-3"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Browse
                                          </label>
                                        </p>
                                      </div>
                                    )}
                                    <input
                                      id="file-upload"
                                      type="file"
                                      accept="image/*"
                                      style={{ display: "none" }}
                                      onChange={handleFileSelect}
                                    />
                                  </div>
                                  {error && (
                                    <Alert className="mt-3">{error}</Alert>
                                  )}
                                  {image && (
                                    <Button
                                      className="mt-0 delete-preview-image"
                                      onClick={() => setImage(null)}
                                    >
                                      <Image src={deleteIcon} />
                                    </Button>
                                  )}
                                </div>
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                        <Row className="mt-4">
                          <Col
                            lg={isExpanded ? 12 : 3}
                            className={`add-product-btn ${
                              isExpanded ? "styled-btn" : ""
                            }`}
                          >
                            {!isExpanded && (
                              <div className="styled-border-btn">
                                <Button
                                  onClick={handleAddProductClick}
                                  className="btn-light"
                                  style={{ fontSize: "14px" }}
                                >
                                  <i class="fa-solid fa-circle-plus"></i> Add
                                  Product
                                </Button>
                              </div>
                            )}
                          </Col>
                          {isExpanded && (
                            <Col
                              lg={isExpanded ? 12 : 6}
                              className={`add-product-form ${
                                isExpanded ? "" : "d-none"
                              }`}
                            >
                              <div className="form-inner position-relative p-3 mt-4 mb-4 pt-4 shadow-none">
                                <Row>
                                  <Col lg={12}>
                                    <h5
                                      style={{
                                        fontSize: "14px",
                                        background: "#fff",
                                        padding: "0 0px",
                                      }}
                                      className="text-12 mb-4 overlay-title"
                                    >
                                      Product Details
                                    </h5>
                                  </Col>

                                  <Col lg={4} md={6}>
                                    <Form.Group className="mb-3">
                                      <Form.Label
                                        className="fw-500"
                                        style={{ fontSize: "14px" }}
                                      >
                                        Product Name
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="productName"
                                        value={productName}
                                        onChange={(e) =>
                                          setProductName(e.target.value)
                                        }
                                        className="custom-field"
                                        isInvalid={!!errors.productName}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.productName}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={4} md={6}>
                                    <Form.Group className="mb-2">
                                      <Form.Label
                                        className="fw-500"
                                        style={{ fontSize: "14px" }}
                                      >
                                        Product Description
                                      </Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        className="custom-field overflow-hidden"
                                        rows={1}
                                        placeholder=""
                                        value={productDescription}
                                        onChange={(e) =>
                                          setProductDescription(e.target.value)
                                        }
                                        isInvalid={!!errors.productDescription}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.productDescription}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={4}>
                                    <Button
                                      // type="submit"
                                      onClick={handleSubmitProduct}
                                      style={{ fontSize: "14px" }}
                                      className="btn"
                                    >
                                      Save
                                    </Button>
                                  </Col>
                                </Row>
                                <Row></Row>
                              </div>
                            </Col>
                          )}
                          <Col
                            lg={12}
                            className={`product-table ${
                              isExpanded ? "" : "d-none"
                            }`}
                          >
                            {products.length > 0 ? (
                              <Table bordered hover responsive className="mt-3">
                                <thead className="table light-thead">
                                  <tr>
                                    <th>Product</th>
                                    <th>Description</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {products.map((product, index) => (
                                    <tr key={index}>
                                      <td>
                                        <Nav.Link className="text-decoration-underline">
                                          {product.productName}
                                        </Nav.Link>
                                      </td>
                                      <td>
                                        <p className="overflow-description lh-inherit">
                                          {product.productDescription}
                                        </p>
                                      </td>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <Nav.Link
                                            variant="link"
                                            className="me-3"
                                            onClick={(e) => {
                                              handleEditProduct(index);
                                              handleDeleteProduct(index);
                                            }}
                                          >
                                            <Image
                                              style={{ width: "14px" }}
                                              src={editIcon}
                                            />
                                          </Nav.Link>

                                          {/* Delete Button */}
                                          <Nav.Link
                                            variant="link"
                                            className="text-danger"
                                            onClick={() =>
                                              handleDeleteProduct(index)
                                            }
                                          >
                                            <Image
                                              style={{ width: "14px" }}
                                              src={deleteIcon}
                                            />
                                          </Nav.Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col
                            lg={isExpanded ? 12 : 9}
                            className="add-product-footer-btn text-end"
                          >
                            <Button
                              className="btn-light me-2"
                              onClick={(e) => {
                                e.preventDefault();
                                setIsExpanded(false);
                                setProducts([]);
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              onClick={handleSubmit}
                              style={{ fontSize: "14px" }}
                              className="btn"
                              disabled={isSaveDisabled}
                            >
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default AddProduct;
