import React, { useState } from "react";
import "../dashboard.css";
import { Row, Form, Breadcrumb, CardBody, Image } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { Alert } from "react-bootstrap";
import deleteIcon from "../assets/icons/delete.png";
import uploadIcon from "../assets/icons/upload.png";
import { Table, Nav } from "react-bootstrap";
import editIcon from "../assets/icons/edit.png";

const AddAdmin = () => {
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);

  return (
    <>
      <div className="content page-wrapper">
        <div className="page-content">
          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Card.Header className="bg-white border-0 p-0">
                  <div class="d-flex align-items-center">
                    <div>
                      <h5 class="mb-0 fw-500">Admin</h5>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="px-2">
                  <Row>
                    <Col lg={12}>
                      <Form>
                        <div className="form-inner position-relative p-3 mt-3 pt-4 shadow-none">
                          <Row>
                            <Col lg={12}>
                              <h5
                                style={{
                                  fontSize: "14px",
                                  background: "#fff",
                                  padding: "0 0px",
                                }}
                                className="text-12 mb-4 overlay-title"
                              >
                                Admin Details
                              </h5>
                            </Col>
                            <Col lg={4} md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  First Name
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="FirstName"
                                  className="custom-field"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={4} md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Last Name
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="LastName"
                                  className="custom-field"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={4} md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Email ID
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="EmailID"
                                  className="custom-field"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={4} md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Set Password
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="SetPassword"
                                  className="custom-field"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={4} md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Member ID
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="MemberID"
                                  className="custom-field"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={4} md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Manage Permission
                                </Form.Label>
                                <Form.Select
                                  name="company"
                                  className="custom-field"
                                >
                                  <option value="1">View only</option>
                                  <option value="2">Can edit</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group controlId="formBasicCheckbox">
                                <Form.Check
                                  required
                                  type="checkbox"
                                  className="text-light-grey mt-3 form_label"
                                  style={{ fontSize: "14px" }}
                                  checked
                                  label={
                                    <>
                                      Want to make Shantanu as
                                      <a
                                        href="/privacy-policy"
                                        className="text-light-blue"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {" "}
                                        <strong>Super Admin</strong>
                                      </a>
                                    </>
                                  }
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mt-4 text-start">
                            <Col lg={12} className="add-product-footer-btn">
                              <Button
                                style={{ fontSize: "14px" }}
                                className="btn"
                              >
                                Save
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default AddAdmin;
