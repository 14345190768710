import React from "react";
import { Card, Row, Col, Button, Image, Form, Nav } from "react-bootstrap";
import "../../../src/dashboard.css";
import preview from "../../assets/icons/view.png";
const CustomCardForData = ({
  title,
  description,
  imageSrc,
  altText,
  onPreview,
  checkbox = false,
  showPreviewButton = true,
  imageStyle,
}) => {
  return (
    <Col lg={4} sm={6} className="d-flex mb-4">
      {checkbox && (
        <Form.Check
          type="checkbox"
          className="checkbox-custom mt-0"
          onChange={checkbox.onChange}
        />
      )}
      <Card className="custom-card inner-card w-100">
        <Card.Body className="p-0">
          <Row>
            <Col lg={12}>
              <div className="card-header-section">
                <div>
                  <Card.Title className="card-title">{title}</Card.Title>
                </div>
              </div>

              <div className="card-image d-flex align-items-center justify-content-center">
                <Image
                  src={imageSrc}
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "none !important",
                  }}
                  alt="Report"
                  className="image-hover"
                />
                <div className="preview-link">
                  <Image
                    src={preview}
                    alt="Click to Preview"
                    className="preview-image"
                  />
                  <Nav.Link className="text-danger p-0 fw-400">
                    Click to Preview
                  </Nav.Link>
                </div>
              </div>

              <Card.Text className="card-text">{description}</Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default CustomCardForData;
