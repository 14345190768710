import React, { useState, useEffect } from "react";
import "../dashboard.css";
import { Row, Form, Breadcrumb, Image } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { createResearchDataRequestBody } from "../request/requestBodies";
import { validateCreateResearchDataForm } from "../request/validationFunctions";
import researchDataService from "../services/researchDataService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import backIcon from "../assets/backIcon.png"
const AddDataReport = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  console.log("id", id);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState(createResearchDataRequestBody);

  useEffect(() => {
    if (id) {
      // Fetch data if id exists (edit mode)
      researchDataService
        .getResearchDataById(id)
        .then((res) => {
          const data = res.data;
          setFormData({
            blobLocation: data.blobLocation,
            blobKey: data.blobKey,
            workspaceID: data.workspaceID,
            tenantID: data.tenantID,
            fileName: data.fileName,
            fileDescription: data.fileDescription,
            status: data.status, // Optional, depending on if you need to show it
            createdBy: data.createdBy, // Optional, if required in the form
          });
        })
        .catch((error) => {
          toast.error("Error fetching report data!");
        });
    }
  }, [id]);

  console.log("formData", formData);

  const handleSaveClick = async () => {
    const newErrors = validateCreateResearchDataForm(
      formData.blobLocation,
      formData.blobKey,
      formData.workspaceID,
      formData.tenantID,
      formData.fileName,
      formData.fileDescription
    );
    setError(newErrors);

    const requestBody = {
      ...formData,
      status: "Active",
      createdBy: localStorage.getItem("email"),
    };

    if (!newErrors) {
      try {
        if (id) {
          // Update existing report
          await researchDataService.updateDataReport(id, requestBody);
          toast.success("Report updated successfully!");
        } else {
          // Create new report
          await researchDataService.createResearchData(requestBody);
          toast.success("Report created successfully!");
        }
        navigate("/DataReport");
      } catch (error) {
        toast.error("Error occurred while saving!");
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // const navigate = useNavigate();
  // const [error, setError] = useState(null);
  // const [formData, setFormData] = useState(createResearchDataRequestBody);
  // console.log("formData", formData);

  // const handleSaveClick = async () => {
  //   const newErrors = validateCreateResearchDataForm(
  //     formData.blobLocation,
  //     formData.blobKey,
  //     formData.workspaceID,
  //     formData.tenantID,
  //     formData.fileName,
  //     formData.fileDescription
  //   );
  //   console.log("newErrors", newErrors);
  //   setError(newErrors);

  //   const requestBody = {
  //     ...formData,
  //     status: "Active",
  //     createdBy: localStorage.getItem("email"),
  //   };
  //   if (!newErrors) {
  //     await researchDataService
  //       .createResearchData(requestBody)
  //       .then((res) => {
  //         console.log("res", res);
  //         toast.success("Success!", {
  //           position: "top-right", // Use string directly if POSITION is undefined
  //           autoClose: 3000, // duration in milliseconds
  //         });
  //         navigate("/DataReport");
  //       })
  //       .catch((error) => {
  //         toast.error("Error occurred while saving!", {
  //           position: "top-right", // Use string directly
  //           autoClose: 3000,
  //         });
  //       });
  //   }
  // };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({ ...prevData, [name]: value }));
  // };

  return (
    <>
      <ToastContainer />
      <div className="content page-wrapper">
      <div className="page-content">
      <div class="page-breadcrumb  d-sm-flex align-items-center mb-3">
                        <Breadcrumb className='mb-0'>
                            <Breadcrumb.Item >
                            <Image className="me-1" src={backIcon} style={{width:'16px', cursor:'pointer', verticalAlign:'text-bottom'}} onClick={() => window.history.back()} alt="back"/> <a href="/DataReport" className="fw-400">Data Report</a></Breadcrumb.Item>
                            <Breadcrumb.Item className='fw-500 no-link' style={{cursor:'unset'}}><strong className="fw-500">Add Data Report</strong></Breadcrumb.Item>
                        </Breadcrumb>
                     
                    </div>
          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Card.Header className="bg-white border-0 p-0">
                  <div class="d-flex align-items-center">
                    <div>
                      <h5 class="mb-0 fw-500">Data Report</h5>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="px-2">
                  <Row>
                    <Col lg={12}>
                      <Form>
                        <div className="form-inner position-relative p-3 mt-3 pt-4 shadow-none">
                          <Row>
                            <Col lg={12}>
                              <h5
                                style={{
                                  fontSize: "14px",
                                  background: "#fff",
                                  padding: "0 0px",
                                }}
                                className="text-12 mb-4 overlay-title"
                              >
                                Data Report Details
                              </h5>
                            </Col>
                            <Col xxl={3} xl={4} lg={4} md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  BLOB Location
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="blobLocation"
                                  className="custom-field"
                                  onChange={(e) => handleInputChange(e)}
                                  isInvalid={!!error?.blobLocation}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {error?.blobLocation}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col xxl={3} xl={4} lg={4} md={6} sm={12}>
                              <Form.Group className="mb-3">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  BLOB Key
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="blobKey"
                                  className="custom-field"
                                  onChange={(e) => handleInputChange(e)}
                                  isInvalid={!!error?.blobKey}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {error?.blobKey}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col xxl={3} xl={4} lg={4} md={6} sm={12}>
                              <Form.Group className="mb-2">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Workspace ID
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="workspaceID"
                                  className="custom-field"
                                  onChange={(e) => handleInputChange(e)}
                                  isInvalid={!!error?.workspaceID}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {error?.workspaceID}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col xxl={3} xl={4} lg={4} md={6} sm={12}>
                              <Form.Group className="mb-2">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Tenant ID
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="tenantID"
                                  className="custom-field"
                                  onChange={(e) => handleInputChange(e)}
                                  isInvalid={!!error?.tenantID}
                                  maxLength={20}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {error?.tenantID}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col xxl={3} xl={4} lg={4} md={6} sm={12}>
                              <Form.Group className="mb-2">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  File Name
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="fileName"
                                  className="custom-field"
                                  onChange={(e) => handleInputChange(e)}
                                  isInvalid={!!error?.fileName}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {error?.fileName}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col xxl={3} xl={4} lg={4} md={6} sm={12}>
                              <Form.Group className="mb-2">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  File Description
                                </Form.Label>
                                <Form.Group controlId="formTextarea">
                                  <Form.Control
                                    name="fileDescription"
                                    as="textarea"
                                    className="custom-field overflow-hidden"
                                    rows={1}
                                    placeholder=""
                                    onChange={(e) => handleInputChange(e)}
                                    isInvalid={!!error?.fileDescription}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {error?.fileDescription}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                        <Row className="mt-4">
                          <Col
                            lg={12}
                            className="add-product-footer-btn text-end"
                          >
                            <Button className="btn-light me-2">Cancel</Button>
                            <Button
                              style={{ fontSize: "14px" }}
                              className="btn"
                              onClick={(e) => {
                                e.preventDefault();
                                handleSaveClick();
                              }}
                            >
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default AddDataReport;
